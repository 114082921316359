"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function captureClick(analytics) {
  var win = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window;
  var doc = win.document;
  doc.addEventListener('click', function (event) {
    var el = event.target;
    while (el && el !== doc) {
      var capture = el.getAttribute('analytics');
      if (capture) {
        analytics.report('click', {
          value: capture
        });
        break;
      }
      el = el.parentNode;
    }
  });
}
var _default = exports["default"] = captureClick;