"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _uuid = _interopRequireDefault(require("uuid"));
var _browserCookies = _interopRequireDefault(require("browser-cookies"));
var _WebStorage = _interopRequireDefault(require("../../utils/WebStorage"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var getBaseUrl = function getBaseUrl() {
  if (typeof window === 'undefined') {
    return '';
  }
  var storage = window.localStorage;
  return storage && storage.getItem('baseUrl') || (window.env && window.env.baseUrl ? window.env.baseUrl : '');
};
var getSessionId = function getSessionId() {
  if (typeof window === 'undefined') {
    return _uuid["default"].v4();
  }
  var SESSION_NAME = 'TravSessionId';
  var now = new Date().getTime();
  var sessionId = _browserCookies["default"].get(SESSION_NAME);
  if (sessionId) {
    var currentSessionTime = sessionId.split('_')[1] || now;
    var timeDiff = now - currentSessionTime;
    if (timeDiff > 1800000) {
      sessionId = '';
    }
  }
  if (!sessionId) {
    sessionId = _uuid["default"].v4() + '_' + now;
    _browserCookies["default"].set(SESSION_NAME, sessionId, {
      domain: '.travelers.com'
    });
  }
  return sessionId;
};
var getAppName = function getAppName() {
  return process.env.ANALYTICS_APP_NAME + '_' + process.env.LOB;
};
var getClientId = function getClientId() {
  var obj = {};
  if (typeof window !== 'undefined') {
    var webStorage = _WebStorage["default"].getInstance();
    var clientId = webStorage.getAsString('clientId') || '';
    if (!clientId) {
      for (var i = 0; i < 23; i += 1) {
        clientId += Math.floor(Math.random() * 9);
      }
      webStorage.set('clientId', clientId);
      obj.isNewClientId = true;
    }
    obj.clientId = clientId;
  }
  return obj;
};
var env = {
  appName: getAppName(),
  baseUrl: getBaseUrl(),
  client: getClientId(),
  sessionId: getSessionId()
};
var _default = exports["default"] = env;