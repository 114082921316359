"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _oktaAuthJs = require("@okta/okta-auth-js");
var config = {
  issuer: process.env.OKTA_ISSUER,
  clientId: process.env.OKTA_CLIENTID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
  tokenManager: {
    storage: 'sessionStorage'
  }
};
var oktaAuth = new _oktaAuthJs.OktaAuth(config);
var _default = exports["default"] = oktaAuth;