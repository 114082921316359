"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _getters = _interopRequireDefault(require("./getters"));
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var SEND_DELAY = 1000; // 1 second
var QUEUE_SIZE_THRESHOLD = 200;
var queue = [];
var timeoutId = 0;
var addEvent = function addEvent(event) {
  window.clearTimeout(timeoutId);
  queue.push(event);
  if (queue.length > QUEUE_SIZE_THRESHOLD) {
    flushEvents();
  } else {
    timeoutId = window.setTimeout(flushEvents, SEND_DELAY);
  }
};
var flushEvents = function flushEvents(wait) {
  var events = queue.splice(0, queue.length);
  if (events.length) {
    sendEvents(events, wait);
  }
};
var updateQueryStringParameter = function updateQueryStringParameter() {
  var url = "".concat(_getters["default"].baseUrl || '', "/api/v1/events"); // TODO: take from common place like store once approach is finalize

  if (_getters["default"].appName) {
    url = "".concat(url);
  }
  return url;
};
var sendEvents = function sendEvents(events, wait) {
  var url = updateQueryStringParameter();
  return new Promise(function (resolve, reject) {
    _axios["default"].post(url, events).then(function (response) {
      resolve(response.data[0]);
    })["catch"](function (err) {
      reject(err);
    });
  });
};
var _default = exports["default"] = {
  add: addEvent,
  flush: function flush(wait) {
    window.clearTimeout(timeoutId);
    flushEvents(wait);
  }
};