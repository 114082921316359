"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearSessionData = clearSessionData;
exports.getClientId = getClientId;
exports.getSessionId = getSessionId;
exports.isTrvAuthCookieExists = isTrvAuthCookieExists;
exports.setSessionCookies = setSessionCookies;
var _universalCookie = _interopRequireDefault(require("universal-cookie"));
var _uuid = require("uuid");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var cookies = new _universalCookie["default"]();
function isTrvAuthCookieExists() {
  return !!(cookies.get('SMSESSION') || cookies.get('TravAuthE') || process.env.NODE_ENV !== 'production');
}
function clearSessionData() {
  var cookieNames = ['SMSESSION', 'TravAuthE', 'TravAuthV', 'TravSessionId', 'channels'];
  var options = {
    domain: window.location.hostname === 'localhost' ? 'localhost' : 'travelers.com',
    path: '/'
  };
  cookieNames.forEach(function (key) {
    cookies.remove(key, options);
  });
}
function getSessionId() {
  return cookies.get('TravSessionId');
}
function getClientId() {
  var clientId = cookies.get('clientId');
  if (!clientId) {
    // generate a 23-digit random number and store it
    clientId = '';
    for (var i = 0; i < 23; i += 1) {
      clientId += Math.floor(Math.random() * 10);
    }
  }
  return clientId;
}
function setSessionCookies() {
  var correlId = process.env.REACT_APP_CORREL_ID ? process.env.REACT_APP_CORREL_ID : 'TravMyTBICorrelationId';
  var clientId = process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : 'TravMyTBIClientId';
  var cookieConfig = {
    domain: '.travelers.com',
    path: '/'
  };
  var travSessionId = getSessionId();
  var clientIdVar = getClientId();
  if (!travSessionId) {
    travSessionId = (0, _uuid.v4)();
    cookies.set('TravSessionId', travSessionId, cookieConfig);
  }
  cookies.set(correlId, travSessionId, cookieConfig);
  cookies.set(clientId, clientIdVar, cookieConfig);
}